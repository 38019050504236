<template>
  <div>
    <h3>Featured</h3>
    <b-card no-body>
      <b-tabs content-class="mt-1" fill card
              v-model="tabIndex">
        <b-tab title="MUSIC ALBUM"
               :title-link-class="linkClass(0)"
               active>
          <featured-album></featured-album>
        </b-tab>

        <b-tab title="VIDEO"
               :title-link-class="linkClass(1)"
               lazy>
          <featured-media></featured-media>
        </b-tab>
      </b-tabs>
    </b-card>

    <!-- Dialog -->
    <v-dialog/>
  </div>
</template>

<script>
  const FeaturedMedia =
    () => import('@/components/admin/featured/FeaturedMedia.vue');
  const FeaturedAlbum =
    () => import('@/components/admin/featured_album/FeaturedAlbum.vue');

  export default {
    name : 'Featured',
    data() {
      return {
        tabIndex : 0,
      }
    },
    components : {
      FeaturedMedia,
      FeaturedAlbum,
    },
    methods : {
      linkClass(idx) {
        if (this.tabIndex === idx)
          return [ 'bg-primary', 'text-light', 'font-weight-bold', 'inf-tab' ];
        
        return [ 'bg-light', 'text-info', 'inf-tab' ];
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/featured/featured";
</style>
